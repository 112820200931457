import React, {
  Suspense,
  lazy,
  ReactElement,
  useEffect,
  useState,
} from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { initFirebase } from "./utils/firebase";

import Loader from "./components/Loader";

const Navbar = lazy(() => import("./components/NavBar"));
const Footer = lazy(() => import("./components/Footer"));

const Home = lazy(() => import("./Views/Home"));
const Session = lazy(() => import("./Views/Session"));
const Sessions = lazy(() => import("./Views/Sessions"));
const TOS = lazy(() => import("./Views/TOS"));
const Privacy = lazy(() => import("./Views/Privacy"));
const WhatsNew = lazy(() => import("./Views/WhatsNew"));
const FAQ = lazy(() => import("./Views/FAQ"));

const LoadingView = () => (
  <div className="container-fluid my-5 py-5">
    <div className="row my-5 py-5">
      <div className="col my-5 py-5">
        <Loader />
      </div>
    </div>
  </div>
);

function App() {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    initFirebase()
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  if (loading) {
    return <LoadingView />;
  }

  return (
    <Router>
      <Suspense fallback={<LoadingView />}>
        <div className="app-wrapper">
          <Switch>
            <Route
              exact
              path="/view"
              render={() => (
                <>
                  <Navbar />
                  <Sessions />
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/view/:sessionId"
              render={(props) => (
                <>
                  <Navbar />
                  <Session {...props} />
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/tos"
              render={() => (
                <>
                  <Navbar />
                  <TOS />
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/privacy"
              render={() => (
                <>
                  <Navbar />
                  <Privacy />
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/whats-new"
              render={() => (
                <>
                  <Navbar />
                  <WhatsNew />
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/faq"
              render={(props) => {
                if (props.location.search === "?ui=minimal") {
                  return <FAQ {...props} />;
                } else {
                  return (
                    <>
                      <Navbar />
                      <FAQ {...props} />
                      <Footer />
                    </>
                  );
                }
              }}
            />
            <Route
              exact
              path="/"
              render={() => (
                <>
                  <Navbar />
                  <Home />
                  <Footer />
                </>
              )}
            />
            {/* <Route path="/" component={Home} /> */}
          </Switch>
        </div>
      </Suspense>
    </Router>
  );
}

export default App;
