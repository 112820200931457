import React from "react";

import styles from "./styles.module.scss";

import record_img from "../../assets/img/record.png";

const Loader = () => (
  <div className={styles.wrapper}>
    <img src={record_img} alt="loading spinner" className={styles.img} />
  </div>
);

export default Loader;
