const config = {
  apiKey: "AIzaSyDXW6Ue_sWr9XRfmuwDzYrydVQHhGQPu-c",
  authDomain: "bitte-6bf76.firebaseapp.com",
  databaseURL: "https://bitte-6bf76.firebaseio.com",
  projectId: "bitte-6bf76",
  storageBucket: "bitte-6bf76.appspot.com",
  messagingSenderId: "298705618486",
  appId: "1:298705618486:web:005600d0cf1f47b5a33a97",
  measurementId: "G-20J8RP6HRH",
};

export const initFirebase = async () => {
  const firebase = await (await import("firebase/app")).default;

  await Promise.all([
    import("firebase/analytics"),
    import("firebase/firestore"),
  ]);

  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }

  return firebase;
};
